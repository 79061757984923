<template>
  <div class="viewcontent">
    <form :action="url" @submit.prevent="sendData" method="post">
      <!-- <input type="hidden" :value="data.amount" name="amount" />
      <input type="hidden" :value="data.curreny" name="currency" />
      <input type="hidden" :value="data.description" name="description" /> -->
      <button class="button primary_bg_color contrast_color" type="submit">
        Confirm Pay
      </button>
    </form>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'PayTest',
    data() {
      return {
        url: 'https://www.bressolcreatiu.com/pay/index.php',
        urlReturn: window.location.origin,
        amount: 1,
        currency: 'cny', //usd, eur,cny
        description: 'Payment test for OplusExpo',
      }
    },
    methods: {
      sendData() {
        let saveURL = this.url
        let urlError = this.toHex(this.urlReturn + '/#/payerror')
        let urlSuccess = this.toHex(this.urlReturn + '/#/paysuccess')
        location.href =
          saveURL +
          '?amount=' +
          this.amount +
          '&currency=' +
          this.currency +
          '&description=' +
          this.description +
          '&lang=' +
          this.languageSelected.toUpperCase() +
          '&successurl=' +
          urlSuccess +
          '&errorurl=' +
          urlError
      },

      toHex(str) {
        let hex = ''
        try {
          hex = unescape(encodeURIComponent(str))
            .split('')
            .map(function(v) {
              return v.charCodeAt(0).toString(16)
            })
            .join('')
        } catch (e) {
          hex = str
          console.log('invalid text input: ' + str)
        }
        return hex
      },
    },
    computed: {
      ...mapState(['languageSelected']),
    },
  }
</script>

<style></style>
